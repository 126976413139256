window.addEvent("domready", function() {
  formCheck.onValidateFailure = fixErrorsPosition;
  formCheck.onValidateFailurePart = fixErrorsPosition;

  formCheck.onValidateSuccessPart = function() {
    formCheck.reinitialize("forced");
  };
});

var fixErrorsPosition = function() {
  var chkErrors = $$(".elem-chck .fc-error");
  if (chkErrors.length == 0) return;

  chkErrors.each(function(el) {
    el.addClass("errors-chk");
    el.inject(el.getParent(".login-checkbox"), "after");
  });
};

if ("serviceWorker" in navigator) {
  window.addEventListener("load", function() {
    navigator.serviceWorker
      .register("/sw.js")
      .then(function(registration) {}, function(err) {});
  });
}
