window.addEvent("domready", function() {
  if (!$("errorForm")) return;

  window.formCheck = new FormCheck("errorForm", {
    submit: false,
    display: {
      showErrors: 1,
      titlesInsteadNames: 1,
      addClassErrorToField: 1,
      indicateErrors: 2,
      errorsLocation: 3
    },
    onValidateSuccess: function() {
      sendError();
    }
  });
});

function sendError() {
  var text = $("errorDesc").get("value");
  var key = $("errorDesc").get("data-id");
  var loader = $("waiting-main");

  new Request.JSON({
    url: $("errorForm").getAttribute("action"),
    onRequest: function() {
      loader.addClass("open");
    },
    onSuccess: function(response) {
      loader.removeClass("open");

      if (response == "fail") {
        var message = transObj.errorSendFailLabel;
        var cls = "error";
      } else {
        var message = transObj.errorSendSuccessLabel;
        var cls = "success";
      }

      $("errorDesc").set("value", "");
      $$(".field-ok").removeClass("field-ok");

      mainToast(message, cls);
    },
    onFailure: function(e) {
      loader.removeClass("open");
      if (e.status === 0) mainToast(transObj.offlineMsg, "error");
    }
  }).post({
    text: text,
    key: key
  });
}
